import React from 'react'
import {navigate} from 'gatsby'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from 'reducers'
import {CandidateService, PlanService} from 'services'
import {showError, setLoading, quitLoading, formatMiles} from 'utils'
import {Layout} from 'widgets'
import {QuoteRequest, PlanParam, PlanResponse, Plan, Coverage, Travel, ComparedPlanResponse} from 'models'
import moment from 'moment'
import {AirplaneSeparator, Grid1XX, Grid2X2} from 'images'
import {SEO, Button, Modal} from 'components'

const Search = () => {
    const quoteRequest: QuoteRequest | null = useSelector((state: RootState) => state.quoteRequest)
    const dispatch = useDispatch()
    const [plansBackup, setPlansBackup] = React.useState<Plan[]>([])
    const [plans, setPlans] = React.useState<Plan[]>([])
    const [travel, setTravel] = React.useState<Travel>()
    const [model, setModel] = React.useState<boolean>(true)
    const [coverages, setCoverages] = React.useState<Coverage[] | null>(null)
    const [visible, setVisible] = React.useState<boolean>(false)
    const [selectedPlan, setSelectedPlan] = React.useState<Plan | null>(null)
    const [plansToCompare, setPlansToCompare] = React.useState<number[]>([])
    const [comparedPlans, setComparedPlans] = React.useState<Array<string[]> | null>([])
    const [visibleComparePlans, setVisibleComparePlans] = React.useState<boolean>(false)
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(urlSearchParams.entries());
    const optionsPax = [
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "5",
          value: "5",
        },
        {
          label: "6",
          value: "6",
        },
        {
          label: "7",
          value: "7",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "8",
          value: "8",
        },
        {
          label: "9",
          value: "9",
        },
        {
          label: "10",
          value: "10",
        },
      ];
    const load = async () => {
        if(quoteRequest || queryParams?.candidate){
            setLoading()
            try {
                let _quoteRequest = { ... quoteRequest }
                if(queryParams?.candidate){
                    const candidate = await CandidateService.get(queryParams.candidate)
                    let destiny_id = ''
                    switch (candidate.destino) {
                        case 'Mundo':
                            destiny_id = 'world'
                            break;
                        case 'Norte América':
                            destiny_id = 'northAmerica'
                            break;
                        case 'Sur América':
                            destiny_id = 'southAmerica'
                            break;
                        case 'Europa':
                            destiny_id = 'europe'
                            break;
                        case 'Central América':
                            destiny_id = 'centralAmerica'
                            break;
                        case 'Nacional':
                            destiny_id = 'nacional'
                            break;
                        default:
                            destiny_id = ''
                            break;
                    }
                    const country = candidate.intl_tel_input_data_value
                    const country_phone = candidate.whatsapp.replace('+'+(candidate.intl_tel_input_data_value?.dialCode || ''), '')
                    const concat: QuoteRequest = {
                        ... _quoteRequest,
                        destiny_id,
                        name: candidate?.name ||  _quoteRequest.name || '',
                        lastname: candidate?.lastname || _quoteRequest.lastname || '',
                        email: candidate.email,
                        since: moment(candidate.fecha_salida).toDate(),
                        until: moment(candidate.fecha_regreso).toDate(),
                        country_phone,
                        country
                    }
                    _quoteRequest = concat
                    dispatch({
                        type: 'SET_QUOTE_REQUEST',
                        payload: concat
                    })
                }
                let params: PlanParam = {
                    nombre: _quoteRequest.name || '',
                    apellidos: _quoteRequest.lastname,
                    whatsapp: '+'+(_quoteRequest.country?.dialCode || '')+_quoteRequest.country_phone,
                    email: _quoteRequest.email || '',
                    fecha_salida: moment(_quoteRequest.since).format('YYYY-MM-DD'),
                    fecha_regreso: moment(_quoteRequest.until).format('YYYY-MM-DD'),
                    destino: _quoteRequest.destiny_id || '',
                    edades: _quoteRequest.passengers || [],
                    origen: _quoteRequest.country?.name || ''
                }
                if(queryParams?.candidate){
                    params['sqlId'] = queryParams.candidate
                }
                const res: PlanResponse = await PlanService.get(params)
                let _plans: Plan[] = Object.values(res.planes)?.map((element): Plan => {
                    return {
                        coverages: element.coberturas.map((_element): Coverage  => {
                            return {
                                coverage: _element.cobertura,
                                value: _element.valor
                            }
                        }),
                        contract: element.contract,
                        day_limit: element.day_limit,
                        day_limit_condition: element.day_limit_condition,
                        id: element.id,
                        includesCovid: element.incluyeCovid,
                        age_limit: element.limiteEdad,
                        logo: element.logo,
                        insurance_name: element.nombreAseguradora,
                        name: element.nombrePlan,
                        isPopularPlan: element.planPopular,
                        price: element.precio,
                        insurance_id: element.insurance_id
                    }
                }).sort((a: Plan, b: Plan) => parseFloat(a.price) - parseFloat(b.price))
                const _travel: Travel = {
                    destiny: res.viaje.destino,
                    travel_days: res.viaje.diasDeViaje,
                    back_date: res.viaje.fecha_regreso,
                    start_date: res.viaje.fecha_salida,
                    id: res.viaje.id_sql,
                    name: res.viaje.nombre,
                    passengers_count: res.viaje.numeroPasajeros,
                    results_number: res.viaje.numero_resultados,
                    origin: res.viaje.origen
                }
                if(_quoteRequest?.filterById){
                    console.log('plans', _plans);
                    setPlansBackup(_plans); // todos los planes guardados
                    let auxAll = [];
                    let auxFiltered = [];
                    auxAll = _plans.filter(element => element.insurance_id !== _quoteRequest.filterById); //todos menos assistcard u otro id
                    auxFiltered = _plans.filter(element => element.insurance_id === _quoteRequest.filterById) // solo assistcard y otro id
                    _plans = auxFiltered.concat(auxAll); // primero assistcard, enseguida los demás
                    console.log('plans', _plans);
                }
                setPlans(_plans)
                setTravel(_travel)
            } catch (error:any) {
                showError(error?.message)
                // navigate('/')
            } finally{
                quitLoading()
            }
        }
    }
    const changeModel = (isModel: boolean = true) => {
        setModel(isModel)
    }

    const getColsByModel = (): string => {
        return model ? 'col-md-12 col-sm-12' : 'col-lg-6 col-md-12 col-sm-12 m-0'
    }
    React.useEffect(() => {
        if(quoteRequest || queryParams?.candidate)
            load()
        else
            navigate('/')
    }, [])
    const viewPlanDetail = async (element: Plan) => {
        setLoading()
        try {
            if(travel){
                const res = await PlanService.coverages({
                    id_plan: element.id,
                    id_sql: travel.id
                })
                const _coverages: Coverage[] = res.coberturas.map((element): Coverage => {
                    return {
                        coverage: element.cobertura,
                        value: element.valor
                    }
                })
                setCoverages(_coverages)
                setSelectedPlan(element)
                setVisible(true)
                quitLoading()
            }
        } catch (error) {
            showError('Ha ocurrido un error obteniendo los detalles del plan, verifique su conexión a internet')
            quitLoading()
        }
    }
    const onClose = () => {
        setLoading()
            setVisible(false)
            setCoverages(null)
            setSelectedPlan(null)
        quitLoading()
    }
    const checkAndSetPlansToCompare = (element: Plan, isAdded: boolean) => {
        let _plans = [... plansToCompare]
        if(!isAdded){
            _plans.push(element.id)
        }else{
            const index = _plans.findIndex((_element: number) => _element === element.id)
            if(index !== -1){
                _plans.splice(index, 1)
            }
        }
        setPlansToCompare(_plans)
    }
    const comparePlans = async () => {
        try {
            setLoading()
            if(travel){
                const res: ComparedPlanResponse = await PlanService.compare({
                    id_sql: travel.id,
                    planes: plansToCompare
                })
                let items: Array<string[]> = []
                Object.keys(res.coberturas).forEach((element: string, i: number) => {
                    let item: string[] = []
                    let values: string[] = []
                    if(typeof res.coberturas[element] === 'object'){
                        const newValues = Object.values(res.coberturas[element])
                        //     .map((_element: unknown) => element)
                        if(newValues.length){
                            //@ts-ignore
                            values = newValues
                        }
                    }else{
                        values = res.coberturas[element]
                    }
                    item = [
                        element,
                        ... values
                    ]
                    items.push(item)
                })
                setComparedPlans(items)
                setVisibleComparePlans(true)
            }
        } catch (error) {
            console.log('>>: error > ', error)
            showError()
        }
        finally{
            quitLoading()
        }
    }
    const onCloseComparePlans = () => {
        setLoading()
            setVisibleComparePlans(false)
            setComparedPlans(null)
        quitLoading()
    }
    const changeCandidate = async()=>{
        if(queryParams?.candidate){
            const candidate = await CandidateService.get(queryParams.candidate)
            
            let destiny_id = ''
            switch (candidate.destino) {
                case 'Mundo':
                    destiny_id = 'world'
                    break;
                case 'Norte América':
                    destiny_id = 'northAmerica'
                    break;
                case 'Sur América':
                    destiny_id = 'southAmerica'
                    break;
                case 'Europa':
                    destiny_id = 'europe'
                    break;
                case 'Central América':
                    destiny_id = 'centralAmerica'
                    break;
                case 'Nacional':
                    destiny_id = 'nacional'
                    break;
                default:
                    destiny_id = ''
                    break;
            }
            const country = candidate.intl_tel_input_data_value
            const country_phone = candidate.whatsapp.replace('+'+(candidate.intl_tel_input_data_value?.dialCode || ''), '')
            const auxPax = [];
            if(travel){
                for (let i = 0 ; i < travel?.passengers_count ; i++){
                    auxPax.push(18);
                }
            }
            
            const concat: QuoteRequest = {
                ... candidate,
                destiny_id,
                name: candidate?.name ||  candidate.name || '',
                lastname: candidate?.lastname || candidate.lastname || '',
                email: candidate.email,
                since: moment(candidate.fecha_salida).toDate(),
                until: moment(candidate.fecha_regreso).toDate(),
                passengers: auxPax,
                country_phone,
                country,
            }
            console.log('candidate',candidate);
            console.log('concat',concat);
            await CandidateService.save(concat, queryParams.candidate);
            dispatch({
                type: 'SET_QUOTE_REQUEST',
                payload: concat
            })
        }
    }
    const useThisPlan = async (element: Plan) => {
        setLoading()
            if(travel){
                dispatch({
                    type: 'SET_PLAN',
                    payload: {
                        ... element,
                        id_sql: travel.id
                    }
                })
               await  changeCandidate();
            }
        quitLoading()
        navigate('/checkout')
    }
    const checkPlanLimit = (ageLimit: number): string => {
        let text = 'Sin limite'
        if(ageLimit <= 80){
            text = ageLimit+' Años'
        }
        return text
    }
    const getCoverageText = (coverage: string, isFirstItem: boolean): string => {
        let text = coverage
        const floatVal = parseFloat(text)
        const hasYearsWord = text.includes('años')
        if(!hasYearsWord){
            if(!isFirstItem && floatVal && (!text.includes('-') || !text.includes(' '))){
                text = formatMiles(floatVal, false)
            }
        }
        return text
    }
    const handleChangePax = (e:any)=> {
        const auxTravel:any = {
            ... travel,
            passengers_count: parseInt(e.target.value)
        }
        setTravel(auxTravel);
    }
    return(
        <Layout>
            <SEO
                title="Tu consulta en Compara Assist."
                description="Somos la forma segura y sencilla para consultar las distintas opciones de seguros de viajes y de salud."
                keywords="seguros de viaje mercantil, seguro de viaje internacional mapfre, seguros de viaje para europa, seguro de viaje para españa, seguro de viaje internacional barato, seguros de viaje internacionales, asegura tu viaje, seguro médico internacional barato, seguro médico internacional sanitas, seguro de salud, póliza de seguro internacional, seguro de viaje internacional, seguro de vida internacional, seguro de viajero internacional"
            />
            <Modal
                visible={visibleComparePlans}
                onClose={onCloseComparePlans}
                styles={{
                    overlay:{
                        zIndex: 2
                    },
                    content:{
                        overflowX: 'hidden'
                    }
                }}
            >
                <div className="compare-plans">
                    <div className="w-100 text-center bg-purple">
                        <p className="m-0 p-1 text-white">
                            Listo {travel?.name}, compara los planes que has seleccionado.
                        </p>
                    </div>
                    <div className="row header-insurances">
                        <div className="col-md col-sm-12 insurance-top-card first-item"></div>
                        <React.Fragment>
                            {
                                plansToCompare.map((id: number, i: number) => {
                                    const plan = plans.find((element: Plan) => element.id === id)
                                    return(
                                        <div
                                            className={"col-md col-sm-12 insurance-top-card " +(i === (plansToCompare.length -1) && 'last-item')}
                                            key={i}
                                        >
                                            <img
                                                src={process.env.GATSBY_API_ASSETS+'img/mk/insurances/'+plan?.logo}
                                                alt={plan?.insurance_name || ''}
                                                style={{
                                                    padding: '3% 30%'
                                                }}
                                            />
                                            {
                                                plan?.includesCovid && (
                                                    <div
                                                        className="rounded-pill bg-purple text-center w-100"
                                                    >
                                                        <p className="text-white text-uppercase m-0 p-0">
                                                            cubre covid
                                                        </p>
                                                    </div>
                                                )
                                            }
                                            <div className="w-100 text-center">
                                                <h3 className="text-purple m-0 p-1">
                                                    {plan?.price}
                                                </h3>
                                                <Button
                                                    className="success mb-2"
                                                    label="Seleccionar"
                                                    onClick={() => plan && useThisPlan(plan)}
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </React.Fragment>
                        <React.Fragment>
                            {
                                comparedPlans?.map((element: string[], i: number) => {
                                    return(
                                        <div
                                            className={"w-100 coverage-container "+(i === 0 && 'is-first-child')}
                                            key={i}
                                        >
                                            <div
                                                className="row"
                                            >
                                                {
                                                    element.map((coverage: string, _i: number) => {
                                                        const isFirstItem = _i === 0
                                                        return(
                                                            <div
                                                                key={_i}
                                                                className={("coverage-item col-md-"+(12 / element.length)) +' '+ (isFirstItem ? 'col-sm-12' : 'col-sm')}
                                                            >
                                                                <p
                                                                    className={"text-dark m-0 p-2 ellipsis w-100 "+(!isFirstItem ? 'text-center' : 'text-left')}
                                                                >
                                                                    {
                                                                        getCoverageText(coverage, isFirstItem)
                                                                    }
                                                                </p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </React.Fragment>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={visible}
                onClose={onClose}
                styles={{
                    content : {
                        width: '40%',
                        left: '30%'
                    }
                }}
            >
                <div className="container">
                    <div className="w-100 text-center">
                        <img
                            src={process.env.GATSBY_API_ASSETS+'img/mk/insurances/'+selectedPlan?.logo}
                            alt={selectedPlan?.insurance_name || ''}
                            style={{
                                maxWidth: '60%'
                            }}
                        />
                        {
                            selectedPlan?.contract && (
                                <div
                                    className="container m-2"
                                    style={{
                                        padding: '0 20%'
                                    }}
                                >
                                    <a
                                        className="btn btn-purple text-white cursor-pointer"
                                        href={'https://dev.comparaassist.com/files/contrats/'+selectedPlan?.contract}
                                        target="_blank"
                                    >
                                        Contrato de {selectedPlan?.insurance_name} &nbsp;
                                    </a>
                                </div>
                            )
                        }
                    </div>
                    <React.Fragment>
                        {
                            coverages?.map((element: Coverage, i: number) => {
                                return(
                                    <div
                                        className="row"
                                        key={i}
                                    >
                                        <div className="col-8 ellipsis">
                                            <p className="text-dark-gray">
                                                {
                                                    element.coverage
                                                }
                                            </p>
                                        </div>
                                        <div className="col-4 ellipsis">
                                            <p className="text-dark-gray">
                                                {
                                                    parseFloat(element.value) && formatMiles(parseFloat(element.value), false) || element.value
                                                }
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </React.Fragment>
                </div>
            </Modal>
            <div className="search">
                {
                    plansToCompare.length > 1 && (
                        <div
                            className="widget-float text-center bg-dark-gray rounded-pill"
                        >
                            <p
                                className="text-white m-0 p-1 cursor-pointer"
                                onClick={() => comparePlans()}
                            >
                                Comparar planes
                            </p>
                        </div>
                    )
                }
                {
                    travel && (
                        <div className="travel-bar">
                            <div className="w-100 bg-light-gray text-center">
                                <div className="container">
                                    <p className="text-dark m-0 p-2">
                                        Listo {travel.name}, aqui tienes los resultados de tu busqueda.
                                    </p>
                                    <p className="text-purple p-2 m-0">
                                        Destino: <strong> {travel.destiny} </strong> 
                                        desde el <strong> {travel.start_date} </strong>
                                        hasta el <strong> {travel.back_date} </strong>
                                        <span className="text-pink">
                                            ({travel.travel_days+' días'} ) 
                                        </span>
                                        &nbsp;somos <strong>
                                            <select value={travel.passengers_count} onChange={handleChangePax}>
                                            {
                                            optionsPax.map((option) => (
                                            <option value={option.value}>{option.label}</option>
                                            ))}
                                            </select>
                                              </strong>pasajeros.
                                    </p>
                                    <div className="airplane-separator">
                                        <img
                                            src={AirplaneSeparator}
                                            className="m-0 p-1 w-100"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="plans-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-12 count-container">
                                <p className="text-dark p-2 m-0">
                                    <span className="font-weight-bold">
                                        {plans.length}&nbsp;
                                    </span>
                                    resultados
                                </p>
                            </div>
                            <div className="col-md-4 col-sm-12 d-none d-md-block d-lg-block">
                                <div className="row">
                                    <div
                                        className="col grid-container p-2 d-flex justify-content-end align-items-center"
                                    >
                                        <img
                                            className="m-0 cursor-pointer"
                                            onClick={() => changeModel(false)}
                                            src={Grid2X2}
                                            alt="2x2"
                                        />
                                    </div>
                                    <div
                                        className="col grid-container p-2 d-flex justify-content-start align-items-center"
                                    >
                                        <img
                                            className="m-0 cursor-pointer"
                                            src={Grid1XX}
                                            onClick={() => changeModel()}
                                            alt="1XX"
                                        />
                                    </div>
                                </div>
                            </div>
                            <React.Fragment>
                                {
                                    plans?.map((element: Plan, i: number) => {
                                        const isPlanAddedToCompare = plansToCompare.some((_element: number) => _element === element.id)
                                        const coverages = element.coverages.slice(0, 4)
                                        return(
                                            <div
                                                className={getColsByModel()+" plan"}
                                                key={i}
                                            >
                                                {
                                                    element.includesCovid && (
                                                        <div className="ribbon ribbon-top-left">
                                                            <span>Cubre Covid</span>
                                                        </div>
                                                    )
                                                }
                                                <div className="row">
                                                    <div className={"col-md-4 text-center price-container "+(!model && 'isGrid')}>
                                                        <h3 className="text-purple m-0">
                                                            {element.price}
                                                        </h3>
                                                        <span className="text-dark-gray text-center subtitle">
                                                            Precio aprox. por persona
                                                        </span>
                                                        <div className="container w-100 p-1">
                                                            <Button
                                                                onClick={() => useThisPlan(element)}
                                                                label="Quiero este plan"
                                                                className={"success text-center text-white btn-bordered "+ (!model && 'btn-sm')}
                                                            />
                                                        </div>
                                                        <div className={"row tabs-container "+(!model && ' p-0')}>
                                                            <div className="col col-md-6 w-100 col-sm-6 p-0">
                                                                <Button
                                                                    label={isPlanAddedToCompare ? 'Listo' : "Compara"}
                                                                    className={(isPlanAddedToCompare ? 'pink': 'purple')+" without-right-border-radius"}
                                                                    onClick={() => checkAndSetPlansToCompare(element, isPlanAddedToCompare)}
                                                                    disabled={!isPlanAddedToCompare && plansToCompare.length === 3}
                                                                />
                                                            </div>
                                                            <div className="col col-md-6 w-100 col-sm-6 p-0">
                                                                <Button
                                                                    label="Detalle"
                                                                    onClick={() => viewPlanDetail(element)}
                                                                    className="gray without-left-border-radius"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 logo-container">
                                                        <img
                                                            src={process.env.GATSBY_API_ASSETS+'img/mk/insurances/'+element.logo}
                                                            className={(!model ? 'w-100' : '')}
                                                            alt={element.insurance_name}
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <h3 className="text-dark font-weight-bold text-center text-uppercase m-0">
                                                            { element.name }
                                                        </h3>
                                                        <p className="text-dark text-center age-limite-subtitle">
                                                            (Límite de edad: {checkPlanLimit(element.age_limit)})
                                                        </p>
                                                        <React.Fragment>
                                                            {
                                                                coverages?.map((_element: Coverage, i: number) => {
                                                                    return(
                                                                        <div
                                                                            key={i}
                                                                            className="row coverage-container"
                                                                        >
                                                                            <div
                                                                                className="col-12 col-md-8 col-sm-8 ellipsis"
                                                                            >
                                                                                <p
                                                                                    className="text-center text-sm-left text-md-left text-dark-gray m-0 lead"
                                                                                >
                                                                                    {_element.coverage}
                                                                                </p>
                                                                            </div>
                                                                            <div
                                                                                className="col-12 col-md-4 col-sm-4 ellipsis"
                                                                            >
                                                                                <p
                                                                                    className="text-center text-sm-left text-md-left text-dark-gray m-0 lead"
                                                                                >
                                                                                    {parseFloat(_element.value) && formatMiles(parseFloat(_element.value), false) || 'Incluido'}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </React.Fragment>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {/* {
                                    plans?.length && quoteRequest?.filterById && plansBackup?.length && (
                                        <div className="container p-2 show-more-container">
                                            <Button
                                                className="purple p-1"
                                                label="Ver mas resultados"
                                                onClick={() => {
                                                    setPlans(plansBackup)
                                                    setPlansBackup([])
                                                }}
                                            />
                                        </div>
                                    )
                                } */}
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default Search